<template>
  <div id="music-page">
    <AppContent
      class="music-content"
      :isFullPage="true"
      :isFluid="false"
      :isContentContainer="true"
      :withSidebar="true">
      <b-row class="my-auto" align-v="center">
        <b-col cols="12" md="6">
          <h1>MUSIC</h1>
        </b-col>
        <b-col cols="12" md="6">
          <!-- <b-input-group size="md">
            <b-form-input
              class="keyword-input"
              type="text"
              v-model="keyword"
            ></b-form-input>
            <b-input-group-append
              class="search-icon"
              @click="searchKeyword">
              <b-input-group-text>
                <b-icon icon="search" />
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group> -->
        </b-col>
      </b-row>
      <br><br>
      
      <BannerCarousel />

      <div
        class="music-carousel-container"
        v-for="(category, index) in categories"
        :key="index">
        <b-row class="mt-3 mb-3" align-v="center">
          <b-col cols="7" class="text-right">
            <h1 class="mb-0">{{ category.music_category_title }}</h1>
          </b-col>
          <b-col cols="5" class="text-left">
            <img
              class="music-avatar img-fluid"
              :src="category.music_category_img_url"
              :alt="`${category.music_category_title}, H3, Music, Playlist`"
              @error="setAltThumbnail"
            />
          </b-col>
        </b-row>
        <AppCarousel
          :cards="category.albums"
          :squareCards="true"
          :showTitle="true"
          :arrowColor="'purple'"
          type="album"
          @openAlbumSongs="openAlbumSongs"
        />
      </div>
    </AppContent>

    <modals-container />
  </div>
</template>

<script>
  const AlbumSongsModal = () => import('./AlbumSongsModal');

  export default {
    name : 'MusicPage',
    head : {
      title : {
        inner      : 'Music',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    data() {
      return{
        keyword    : null,
        categories : [],
      }
    },
    components : {
      'AppContent'     : () => import('@/components/layout/AppContent'),
      'AppCarousel'    : () => import('@/components/carousel/AppCarousel'),
      'BannerCarousel' : () => import('@/components/playlist/BannerCarousel'),
    },
    methods : {
      searchKeyword() {
        // console.log("clicked Search button!!");
      },

      getMusicCategories() {
        this.$http.get('api/music/categoriesWithAlbums', {
          params : {
            page    : 1,
            perPage : 100,
          },
        }).then(response => {
          this.categories = response.data.result.data;

          for (let i in this.categories) {
            for (let a in this.categories[i].albums) {
              this.categories[i].albums[a] = {
                albumId   : this.categories[i].albums[a].mobile_album_id,
                name      : this.categories[i].albums[a].album_name,
                thumbnail : this.categories[i].albums[a].album_thumbnail,
              };
              a++;
            }
            i++
          }
        });
      },

      /**
       * Open Album Songs modal
       * @param data
       */
      openAlbumSongs(data) {
        this.$modal.show(AlbumSongsModal, {
          album : data,
        }, {
          height       : 'auto',
          width        : '900px',
          adaptive     : true,
          clickToClose : false,
          classes      : ['playlist-modal'],
        });
      },

      setAltThumbnail(event) {
        event.target.src = require(
          '../../assets/images/music/album-carousel-alt-thumb.png');
      },
    },
    mounted() {
      this.getMusicCategories();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #music-page {
    padding-bottom: 1rem;
    background-image: $music-bg;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    min-height: 100vh;
  }

  .keyword-input, .search-icon {
    border: 1px solid #1356af;
  }

  .search-icon {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    &:hover {
      cursor: pointer;
      .input-group-text {
         background-color: #1356af;
        color: #fff;
      }
    }
  }

  .music-carousel-container {
    text-align: center;
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (max-width: $xs-max) {
    
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    #music-page {
      padding-left: $sidebar-width; //padding-left: 7.5rem;
    }

    .music-avatar {
      height: 7rem;
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    
  }

  @media only screen and (min-width: $special) {
    #music-page {
      padding-left: $sidebar-width; //padding-left: 5rem;
    }

    .music-avatar {
      height: 7rem;
    }
  }

  @media only screen and (min-width: $xl2-min) {
    #music-page {
      padding-left: $sidebar-width; //padding-left: 8rem;
    }

    .music-avatar {
      height: 10rem;
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    
  }
</style>